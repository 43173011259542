import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BookButtons from '../components/BookButtons'

export const VVChaptersTemplate = ({ data, pageContext }) => {
  const filters = {
    relativeDirectory: `${'normal || slow'}`,
    book: 'book',
    chapter: pageContext.chapter,
  }

  const multiPropsFilter = (array, filters) => {
    const filterKeys = Object.keys(filters)
    return array.filter(({ node }) => {
      return filterKeys.every((key) => {
        if (Array.isArray(node[key])) {
          return node[key].match((keyEle) => filters[key].includes(keyEle))
        }
        return filters[key].includes(node[key])
      })
    })
  }

  const filtered = multiPropsFilter(data.allNamesJson.edges, filters)

  return (
    <Layout>
      <BookButtons />
      <section
        style={{ minHeight: '85vh' }}
        className="px-2 py-4 divide-x-2 bg-light-grey grid grid-cols-2 sm:px-16"
      >
        <div>
          <h2 className="text-center">Chapter {pageContext.chapter}</h2>
          <p className="text-center">Normal</p>
          {filtered
            .sort((a, b) => Number(a.node.figure) - Number(b.node.figure))
            .map(({ node }) => {
              return (
                <div key={node.id} className="text-center space-y-2">
                  {node.relativeDirectory === 'normal' ? (
                    <>
                      <p>{node.name}</p>
                      <audio
                        className="block w-24 mx-auto sm:w-64 sm:mx-auto"
                        controls
                        preload="false"
                        src={node.url.publicURL}
                      >
                        <track
                          src="captions_en.vtt"
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                        Your browser doesn&#39;t support audio
                      </audio>
                    </>
                  ) : null}
                </div>
              )
            })}
        </div>
        <div>
          <h2 className="text-center">Chapter {pageContext.chapter}</h2>
          <p className="text-center">Slow</p>
          {filtered
            .sort((a, b) => Number(a.node.figure) - Number(b.node.figure))
            .map(({ node }) => {
              return (
                <div key={node.id} className="text-center space-y-2">
                  {node.relativeDirectory === 'slow' ? (
                    <>
                      <p>{node.name}</p>
                      <audio
                        className="block w-24 mx-auto sm:w-64 sm:mx-auto"
                        controls
                        preload="false"
                        src={node.url.publicURL}
                      >
                        <track
                          src="captions_en.vtt"
                          kind="captions"
                          srcLang="en"
                          label="english_captions"
                        />
                        Your browser doesn&#39;t support audio
                      </audio>
                    </>
                  ) : null}
                </div>
              )
            })}
        </div>
      </section>
    </Layout>
  )
}

export default VVChaptersTemplate

export const aboutPageQuery = graphql`
  query VChapterPage {
    allNamesJson(sort: { fields: name, order: ASC }) {
      edges {
        node {
          chapter
          id
          name
          figure
          relativePath
          relativeDirectory
          book
          url {
            publicURL
          }
        }
      }
    }
  }
`
